import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirebase, useFirestore } from 'react-redux-firebase';
// redux
import { login, register, logout } from '../redux/slices/authJwt';

// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth(method = 'firebase') {
  // Firebase Auth
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { auth, profile } = useSelector((state) => state.firebase);

  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector((state) => state.authJwt);

  // JWT Auth
  if (method === 'jwt') {
    return {
      method: 'jwt',
      user,
      isLoading,
      isAuthenticated,

      login: ({ email, password }) =>
        dispatch(
          login({
            email,
            password
          })
        ),

      register: ({ email, password, firstName, lastName }) =>
        dispatch(
          register({
            email,
            password,
            firstName,
            lastName
          })
        ),

      logout: () => dispatch(logout()),

      resetPassword: () => {},

      updateProfile: () => {}
    };
  }

  // Firebase Auth
  return {
    method: 'firebase',
    user: {
      uid: auth.uid,
      displayName: auth.displayName || profile.displayName || '',
      firstname: profile.firstname || profile.firstname || '',
      lastname: profile.lastname || profile.lastname || '',
      email: auth.email || '',
      companyuid: profile.companyuid || '',
      instance: profile.instance || '',
      photoURL: profile.photoURL || profile.photoURL || '',
      phoneNumber: auth.phoneNumber || profile.phoneNumber || '',
      country: profile.country || '',
      address: profile.address || '',
      state: profile.state || '',
      city: profile.city || '',
      zipCode: profile.zipCode || '',
      about: profile.about || '',
      role: profile.role || [],
      isPublic: profile.isPublic || false,
      timeAdvised: profile.timeAdvised || '0',
      timeOnWebsite: profile.timeOnWebsite || '0',
      timeOnCourse: profile.timeOnCourse || '0',
      token: auth.stsTokenManager ? auth.stsTokenManager.accessToken : '',
      facforpro_id: profile.facforpro_id ? profile.facforpro_id : ''
    },
    isLoading: !isLoaded(auth),
    isAuthenticated: !isEmpty(auth),

    login: ({ email, password }) =>
      firebase.login({
        email,
        password
      }),
    loginWithGoogle: () => firebase.login({ provider: 'google', type: 'popup' }),

    loginWithFaceBook: () => firebase.login({ provider: 'facebook', type: 'popup' }),

    loginWithTwitter: () => firebase.login({ provider: 'twitter', type: 'popup' }),

    register: ({ email, password, firstName, lastName }) =>
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          firestore
            .collection('users')
            .doc(res.user.uid)
            .set({
              uid: res.user.uid,
              email,
              displayName: `${firstName} ${lastName}`
            });
        }),

    logout: () => firebase.logout(),

    resetPassword: (email) => firebase.resetPassword(email),

    updateProfile: (values) =>
      firebase.updateProfile({}).then((res) => {
        firestore
          .collection('users')
          .doc(res.id)
          .set({ ...values }, { merge: true });
      })
  };
}
