// @ts-nocheck
/* eslint-disable */

import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import useAuth from '../hooks/useAuth';
import { COLLECTIONS, DEF_ENV } from '../config';
import { PATH_DASHBOARD, ROOTS_AUTH } from '../routes/paths';
// hooks
import useSettings from '../hooks/useSettings';
import LoadingScreen from '../components/LoadingScreen';

export const AppContext = React.createContext();
const AppProvider = ({ ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const { user } = useAuth();
  const [myCompany, setMyCompany] = useState({});
  const [env, setEnv] = useState({});
  const { applyTheme } = useSettings();
  const [appLoading, setAppLoading] = useState(false);
  useEffect(() => {
    if (
      location.pathname.startsWith(PATH_DASHBOARD.general.entreprises) ||
      location.pathname.startsWith(ROOTS_AUTH)
    ) {
      setMyCompany({});
      applyTheme({});
    }
  }, [location.pathname]);
  useEffect(() => {
    if (myCompany.uid) return;
    axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;

    if (!user.companyuid) {
      axios.defaults.headers.common.Authorization = null;
      return;
    }
    init(user.companyuid);
  }, [user]);
  const init = async (companyuid) => {
    if (!user || !user.uid) return;
    const doc = await firestore.collection(COLLECTIONS.company).doc(companyuid).get();
    const data = doc ? doc.data() : null;
    if (data) {
      setMyCompany(data);
      setEnv({ ...DEF_ENV, company: data.uid });
      if (data.style && applyTheme) {
        applyTheme(data.style);
      }
    }
  };
  const handleExplorerCompany = async (com) => {
    await init(com.uid);
    const urlRedirect = PATH_DASHBOARD.admin.administrateurs;
    history.push(urlRedirect);
  };

  const clearStyle = () => {
    setMyCompany({});
  };
  const isAdmin = () => {
    if (!user) return false;
    if (user.role.includes('admin') || user.role.includes('superadmin')) {
      return true;
    }
    return false;
  };
  const isSuperAdmin = () => {
    if (!user) return false;
    if (user.role.includes('superadmin')) {
      return true;
    }
    return false;
  };
  const isCompanySuperAdmin = () => {
    if (!user) return false;
    if (user.role.includes('superadmincom')) {
      return true;
    }
    return false;
  };
  const isTeacher = () => {
    if (!user) return false;
    if (user.role.includes('teacher')) {
      return true;
    }
    return false;
  };
  const isStudent = () => {
    if (!user) return false;
    if (user.role.includes('student')) {
      return true;
    }
    return false;
  };
  return (
    <AppContext.Provider
      value={{
        isCompanySuperAdmin,
        isTeacher,
        isStudent,
        isSuperAdmin,
        isAdmin,
        env,
        myCompany,
        handleExplorerCompany,
        setAppLoading
      }}
    >
      {appLoading && (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, .3)',
            zIndex: 999999999
          }}
        >
          <LoadingScreen />
        </div>
      )}
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
