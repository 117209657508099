import { Icon } from '@iconify/react';

import personFill from '@iconify/icons-eva/person-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import peopleOutline from '@iconify/icons-eva/people-outline';
import clipboardFill from '@iconify/icons-eva/clipboard-fill';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import settingsFill from '@iconify/icons-eva/settings-fill';
import browserFill from '@iconify/icons-eva/browser-fill';
import layersOutline from '@iconify/icons-eva/layers-outline';

// routes
import { PATH_DASHBOARD, PATH_PAGE, PATH_AUTH } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: 22, height: 22 }} />
);

const ICONS = {
  map: getIcon('ic_map'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  page: getIcon('ic_page'),
  user: getIcon('ic_user'),
  copy: getIcon('ic_copy'),
  error: getIcon('ic_error'),
  charts: getIcon('ic_charts'),
  editor: getIcon('ic_editor'),
  upload: getIcon('ic_upload'),
  animate: getIcon('ic_animate'),
  calendar: getIcon('ic_calendar'),
  elements: getIcon('ic_elements'),
  carousel: getIcon('ic_carousel'),
  language: getIcon('ic_language'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  components: getIcon('ic_components'),
  authenticator: getIcon('ic_authenticator')
};

const sidebarConfig = [
  {
    subheader: 'super-administration',
    role: 'superadmin',
    items: [
      {
        title: 'Sessions scolaires',
        hidden: ['Facforpro', 'ATA'],
        href: PATH_DASHBOARD.admin.sessionfac,
        icon: <Icon icon={browserFill} />
      },
      {
        title: 'Gestion des inscriptions',
        hidden: ['Facforpro', 'ATA'],
        href: PATH_DASHBOARD.admin.assignments,
        icon: <Icon icon={layersOutline} />
      },
      {
        title: 'Gestion des programmes',
        hidden: ['Facforpro', 'ATA'],
        href: PATH_DASHBOARD.admin.programs,
        icon: <Icon icon={clipboardFill} />
      },
      {
        title: 'Administrateurs',
        href: PATH_DASHBOARD.admin.administrateurs,
        icon: <Icon icon={personFill} />
      },
      {
        title: 'Gestion des professeurs',
        href: PATH_DASHBOARD.admin.teachers,
        icon: <Icon icon={peopleFill} />
      },
      {
        title: 'Gestion des apprenants',
        href: PATH_DASHBOARD.admin.students,
        icon: <Icon icon={peopleOutline} />
      },
      {
        title: 'Gestion de whitelist',
        href: PATH_DASHBOARD.admin.whitelist,
        hidden: ['ATA'],
        icon: <Icon icon={peopleOutline} />
      },
      {
        title: 'Gestion des cours',
        href: PATH_DASHBOARD.admin.courseList,
        icon: <Icon icon={bookOpenFill} />
      },
      {
        title: 'Gestion des séances',
        href: PATH_DASHBOARD.admin.session,
        icon: <Icon icon={clockFill} />
      },
      {
        title: 'Gestion des absences',
        href: PATH_DASHBOARD.admin.absences,
        icon: <Icon icon={clockFill} />
      }
    ]
  },
  {
    subheader: 'administration',
    role: 'superadmincom',
    items: [
      {
        title: 'Sessions scolaires',
        hidden: ['Facforpro', 'ATA'],
        href: PATH_DASHBOARD.admin.sessionfac,
        icon: <Icon icon={browserFill} />
      },
      {
        title: 'Gestion des inscriptions',
        hidden: ['Facforpro', 'ATA'],
        href: PATH_DASHBOARD.admin.assignments,
        icon: <Icon icon={layersOutline} />
      },
      {
        title: 'Gestion des programmes',
        hidden: ['Facforpro', 'ATA'],
        href: PATH_DASHBOARD.admin.programs,
        icon: <Icon icon={clipboardFill} />
      },
      {
        title: 'Administrateurs',
        href: PATH_DASHBOARD.admin.administrateurs,
        icon: <Icon icon={personFill} />
      },
      {
        title: 'Gestion des professeurs',
        href: PATH_DASHBOARD.admin.teachers,
        icon: <Icon icon={peopleFill} />
      },
      {
        title: 'Gestion des apprenants',
        href: PATH_DASHBOARD.admin.students,
        icon: <Icon icon={peopleOutline} />
      },
      {
        title: 'Gestion de whitelist',
        href: PATH_DASHBOARD.admin.whitelist,
        hidden: ['ATA'],
        icon: <Icon icon={peopleOutline} />
      },
      {
        title: 'Gestion des cours',
        href: PATH_DASHBOARD.admin.courseList,
        icon: <Icon icon={bookOpenFill} />
      },
      {
        title: 'Gestion des séances',
        href: PATH_DASHBOARD.admin.session,
        icon: <Icon icon={clockFill} />
      },
      {
        title: 'Gestion des absences',
        href: PATH_DASHBOARD.admin.absences,
        icon: <Icon icon={clockFill} />
      }
    ]
  },
  {
    subheader: 'administration',
    role: 'admin',
    items: [
      {
        title: 'Sessions scolaires',
        hidden: ['Facforpro', 'ATA'],
        href: PATH_DASHBOARD.admin.sessionfac,
        icon: <Icon icon={browserFill} />
      },
      {
        title: 'Gestion des inscriptions',
        hidden: ['Facforpro', 'ATA'],
        href: PATH_DASHBOARD.admin.assignments,
        icon: <Icon icon={layersOutline} />
      },
      {
        title: 'Gestion des programmes',
        hidden: ['Facforpro', 'ATA'],
        href: PATH_DASHBOARD.admin.programs,
        icon: <Icon icon={settingsFill} />
      },
      {
        title: 'Gestion des professeurs',
        href: PATH_DASHBOARD.admin.teachers,
        icon: <Icon icon={settingsFill} />
      },
      {
        title: 'Gestion des apprenants',
        href: PATH_DASHBOARD.admin.students,
        icon: <Icon icon={settingsFill} />
      },
      {
        title: 'Gestion de whitelist',
        href: PATH_DASHBOARD.admin.whitelist,
        hidden: ['ATA'],
        icon: <Icon icon={peopleOutline} />
      },
      {
        title: 'Gestion des cours',
        href: PATH_DASHBOARD.admin.courseList,
        icon: <Icon icon={settingsFill} />
      },
      {
        title: 'Gestion des séances',
        href: PATH_DASHBOARD.admin.session,
        icon: <Icon icon={settingsFill} />
      },
      {
        title: 'Gestion des absences',
        href: PATH_DASHBOARD.admin.absences,
        icon: <Icon icon={clockFill} />
      }
    ]
  },
  {
    subheader: 'Etudiant',
    role: 'student',
    items: [
      {
        title: 'Tableau de bord',
        href: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'Messagerie',
        href: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat
      },
      {
        title: 'Profil',
        href: PATH_DASHBOARD.user.profile,
        icon: ICONS.user
      },
      {
        title: 'Cours',
        href: PATH_DASHBOARD.user.courseList,
        icon: ICONS.elements
      },
      {
        title: 'Cours écrits',
        href: PATH_DASHBOARD.courseSupport,
        hidden: ['ATA'],
        icon: ICONS.elements
      },
      {
        title: 'Emploi du temps',
        href: PATH_DASHBOARD.calendar,
        icon: ICONS.calendar
      }
    ]
  },
  {
    subheader: 'professeur',
    role: 'teacher',
    items: [
      {
        title: 'Tableau de bord',
        href: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      {
        title: 'Messagerie',
        href: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat
      },
      {
        title: 'Cours',
        href: PATH_DASHBOARD.admin.courseList,
        icon: ICONS.elements
      },
      {
        title: 'Planning',
        href: PATH_DASHBOARD.admin.session,
        icon: <Icon icon={clockFill} />
      }
    ]
  }
];
export default sidebarConfig;
