// @ts-nocheck
/* eslint-disable */
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const DEF_ENV = {
  instance: 'int'
};

/**
 * Firebase (Backend functions and collections)
 */
export const FIREBASE_BACKEND = {
  url_user_create: 'https://europe-west1-fac-for-pro-e-learning.cloudfunctions.net/createUser',
  url_user_update: 'https://europe-west1-fac-for-pro-e-learning.cloudfunctions.net/updateUser',
  url_user_delete: 'https://europe-west1-fac-for-pro-e-learning.cloudfunctions.net/deleteUser',
  url_user_reset_pwd: 'https://europe-west1-fac-for-pro-e-learning.cloudfunctions.net/resetPasswordUser',
};

export const COLLECTIONS = {
  program: 'program',
  course: 'course',
  teacher: 'teacher',
  student: 'student',
  timers: 'timers',
  users: 'users',
  companies: 'companies',
  session: 'session',
  sessionfac: 'sessionfac',
  assignment: 'assignment',
  company: 'company',
  enseignement: 'enseignement',
  evaluation: 'evaluation',
  studentsNoted: 'studentsNoted',
  studentsAbs: 'studentsAbs',
  writeChapter: 'write_chapter',
  whitelist: 'whitelist',
  notification: 'notification',
  message: 'message'
};

/**
 * Fac For Pro (Laravel API)
 */
const laravelApi = (laravel_api_url) => {
  return {
    /* --- ENSEIGNEMENTS --- */
    url_courses: laravel_api_url + '/courses',
    url_course_by_id: laravel_api_url + '/courseById',
    url_course_info: laravel_api_url + '/getCourseInfo',
    url_student_courses: laravel_api_url + '/getStudentCourses',

    /* --- PROFESSEURS --- */
    url_teachers: laravel_api_url + '/teachers',
    url_teacher_by_id: laravel_api_url + '/teacherById',
    url_teacher_upd: laravel_api_url + '/updateTeacherById',
    url_export_planning_session: laravel_api_url + '/exportPlanningSession',
    url_export_planning_session_xls: laravel_api_url + '/exportPlanningBySessionXls',

    /* --- CRENEAUX --- */
    url_creneaux: laravel_api_url + '/creneauxPaging',
    url_creneau_by_course: laravel_api_url + '/creneauxByCourse',
    url_creneau_by_id: laravel_api_url + '/creneauById',
    url_creneaux_teacher_course: laravel_api_url + '/creneauxByTeacherCourse',
    url_creneaux_students: laravel_api_url + '/creneauxStudent',
    url_creno_propose: laravel_api_url + '/proposeCreno',
    url_creneau_upd: laravel_api_url + '/updateCreneauById',

    /* --- SESSIONS --- */
    url_session_activ: laravel_api_url + '/getAllSessionActiv',

    /* --- ETUDIANTS --- */
    url_students: laravel_api_url + '/studentPaging',
    url_student_info: laravel_api_url + '/getStudentInfo',
    url_students_by_course: laravel_api_url + '/getStudentsByCourse',
    url_student_check: laravel_api_url + '/checkValidStudentInfo',
    url_student_pdf: laravel_api_url + '/getStudentsPdf',
    url_add_note: laravel_api_url + '/addNote',
    url_get_note: laravel_api_url + '/getNote',

    url_presence_iframe: laravel_api_url + '/presenceIframe',
    url_get_whitelist: laravel_api_url + '/getWhitelist',
    url_add_to_whitelist: laravel_api_url + '/addToWhitelist',
    url_remove_from_whitelist: laravel_api_url + '/removeFromWhitelist',
  };
};

const companyIds = {
  Facforpro: {
    id: 'PuaP50VqUKmt2v6ZxbfF',
    fullname: 'FAC FOR PRO EDUCATION',
    email: 'contact@facforpro.com',
  },
  ATA: {
    id: '6zey3yQMu5Jm7cAV9ibK',
    fullname: 'AIR TRAINING ACADEMY',
    email: 'contact@airtraining-academy.com',
  },
};

export const getCompanyIdByName = (name) => {
  return companyIds[name]['id'];
};

export const getCompanyFullnameByName = (name) => {
  return companyIds[name]['fullname'];
};

export const getCompanyEmailByName = (name) => {
  return companyIds[name]['email'];
};

export const COMPANIES = {
  [getCompanyIdByName('Facforpro')]: laravelApi(process.env.REACT_APP_LARAVEL_FFP_ELEARNING_API_URL),
  [getCompanyIdByName('ATA')]: laravelApi(process.env.REACT_APP_LARAVEL_ATA_ELEARNING_API_URL),
};
