// @ts-nocheck
/* eslint-disable */
import React, { useState, useContext } from 'react';
import FacforproProvider from 'src/provider/FacforproProvider';
import useAuth from 'src/hooks/useAuth';
import LinkToLivecourse from './LinkToLivecourse';

const BtnLiveCourse = () => {
  const { user } = useAuth();

  if (!user) return <></>;
  return (
    <FacforproProvider user={user}>
      <LinkToLivecourse />
      <LinkToLivecourse nextLiveCourse={1} />
    </FacforproProvider>
  );
};
export default BtnLiveCourse;
