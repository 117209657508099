// @ts-nocheck
/* eslint-disable */

import React, { useState, useEffect, useContext } from 'react';

import { useFirestore, useFirebase } from 'react-redux-firebase';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { COLLECTIONS, FIREBASE_BACKEND } from '../config';
import { MIconButton } from '../components/@material-extend';
import { AppContext } from './AppProvider';
import {
  getCoursesApi,
  getTeachersApi,
  getTeacherByIdApi,
  getCreneauByIdApi,
  getStudentByIdApi,
  studentCoursesApi,
  courseInfoApi,
  getCourseByIdApi,
  getCreneauxTeacherCourseApi,
  getCreneauxByCourseApi,
  studentsByCourseApi,
  getSessionsActivesApi,
  proposeCrenoForCourseApi,
  updCreneauByIdApi,
  updTeacherByIdApi,
  exportPlanningSessionApi,
  exportPlanningSessionXlsApi,
  createUserApi,
  updateUserApi,
  resetUserPwdByIdApi,
  addNoteApi,
  getNoteApi,
  getPresenceIframeUrl,
  getWhitelistApi,
  addToWhitelistApi,
  removeFromWhitelistApi,
} from '../_apis_/facforpro_api';
import axios from 'axios';

export const FacforproContext = React.createContext();

const FacforproProvider = ({ user, ...props }) => {
  const firestore = useFirestore();
  const firebase = useFirebase();
  const storage = firebase.storage();
  const storageRef = firebase.storage().ref();
  const { env, isTeacher, isStudent, isAdmin, isSuperAdmin, isCompanySuperAdmin } =
    useContext(AppContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [courseInfo, setCourseInfo] = useState(null);
  const [userFacforpro, setUserFacforpro] = useState(null);
  const [loading, setLoading] = useState(false);
  const [whiteListStudent, setWhiteListStudent] = useState([]);
  const [updatedWhitelistIn, setUpdatedWhitelistIn] = useState(null);

  /* init user profile */
  useEffect(() => {
    if (!user) return;
    const init = async () => {
      try {
        if (isStudent()) {
          const u = await getStudentByIdApi(env.company, { etudiant_id: user.facforpro_id });
          setUserFacforpro({ ...user, ...u });
        } else if (isTeacher()) {
          if (!user.facforpro_id) return;
          const u = await getTeacherByIdApi(env.company, { professeur_id: user.facforpro_id });
          setUserFacforpro({ ...user, ...u });
        } else if (isAdmin() || isSuperAdmin() || isCompanySuperAdmin()) {
          setUserFacforpro({ ...user });
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [user]);

  useEffect(() => {
    const initList = async () => {
      try {
        setWhiteListStudent(await getWhitelistApi(env.company));
      } catch (error) {
        throw error;
      }
    };
    initList();
  }, [user, updatedWhitelistIn]);

  const getCreneau = async (creneau_id) => {
    try {
      return getCreneauByIdApi(env.company, { creneau_id: creneau_id });
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const getCreneauInfo = async (creneau_id) => {
    try {
      if (!creneau_id) return;
      const doc = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.session)
        .doc(String(creneau_id))
        .get();
      if (doc && doc.data()) {
        return doc.data();
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const saveCreneauInfo = async (creneau_id, values) => {
    try {
      if (!firestore) return;
      await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.session)
        .doc(String(creneau_id))
        .set({ ...values });
      enqueueSnackbar('Sauvegarde réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const saveStudentReplayTimer = async (etudiant_id, value) => {
    try {
      if (!firestore) return;
      const docRef = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.timers)
        .doc(String(etudiant_id));

        docRef.get().then((doc) => {
          if (doc.exists) {
            docRef.update({
                replay: firebase.firestore.FieldValue.increment(value),
             });
          } else {
            docRef.set({
              replay: value,
            });
          }
        })
    } catch (error) {
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const getStudentTimer = async (etudiant_id) => {
    try {
      if (!firestore) return;
      const docRef = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.timers)
        .doc(String(etudiant_id))
        .get();

      if (docRef && docRef.data()) {
        return docRef.data();
      }
    } catch (error) {
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const saveStudentLiveTimer = async (etudiant_id, value) => {
    try {
      if (!firestore) return;
      const docRef = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.timers)
        .doc(String(etudiant_id));

      docRef.get().then((doc) => {
        if (doc.exists) {
          docRef.update({
            live: firebase.firestore.FieldValue.increment(value),
          });
        } else {
          docRef.set({
            live: value,
          });
        }
      })
    } catch (error) {
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const getStudentInfo = async (etudiant_id) => {
    try {
      return getStudentByIdApi(env.company, { etudiant_id: etudiant_id });
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const uploadStudentFiles = async (model, subCollect, obj, files, userFac) => {
    try {
      const promises = files.map(async (f) => {
        try {
          const urlToSave = `company_${env.company}/${env.instance}/${model}/${
            obj.uid
          }/${Date.now()}_${f.name}`;
          const res = await uploadSingleFile(urlToSave, f);
          return res;
        } catch (error) {
          return null;
        }
      });
      const allUrl = await Promise.all(promises);
      if (model === 'session') {
        let subPromise = allUrl.map(async (it) => {
          const idRandom = Math.floor(Math.random() * new Date().getTime()) + 100000;
          return await firestore
            .collection(env.instance)
            .doc(env.company)
            .collection(COLLECTIONS.session)
            .doc(String(obj.uid))
            .collection(subCollect)
            .doc(String(userFac.facforpro_id))
            .set({ uid: userFac.facforpro_id, ...userFac });
        });
        await Promise.all(subPromise);
        subPromise = allUrl.map(async (it) => {
          const idRandom = Math.floor(Math.random() * new Date().getTime()) + 100000;
          return await firestore
            .collection(env.instance)
            .doc(env.company)
            .collection(COLLECTIONS.session)
            .doc(String(obj.uid))
            .collection(subCollect)
            .doc(String(userFac.facforpro_id))
            .collection(subCollect)
            .doc(String(idRandom))
            .set({ uid: idRandom, ...it });
        });
        await Promise.all(subPromise);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error on upload ', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const uploadFiles = async (model, subCollect, obj, files) => {
    try {
      const promises = files.map(async (f) => {
        try {
          const urlToSave = `company_${env.company}/${env.instance}/${model}/${
            obj.uid
          }/${Date.now()}_${f.name}`;
          const res = await uploadSingleFile(urlToSave, f);
          return res;
        } catch (error) {
          return null;
        }
      });
      const allUrl = await Promise.all(promises);
      if (model === 'session') {
        const subPromise = allUrl.map(async (it) => {
          const idRandom = Math.floor(Math.random() * new Date().getTime()) + 100000;
          return await firestore
            .collection(env.instance)
            .doc(env.company)
            .collection(COLLECTIONS.session)
            .doc(String(obj.uid))
            .collection(subCollect)
            .doc(String(idRandom))
            .set({ uid: idRandom, ...it });
        });
        await Promise.all(subPromise);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error on upload ', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const uploadSingleFile = async (urlToSave, file) => {
    try {
      await storageRef
        .child(urlToSave)
        .put(file)
        .catch((error) => {
          console.error(error);
        });
      const url = await storageRef.child(urlToSave).getDownloadURL();
      const obj = {};
      obj.name = file.name;
      obj.url = url;
      obj.size = file.size;
      obj.updatedAt = new Date();
      return obj;
    } catch (error) {
      return null;
    }
  };

  const removeSingleStudentFile = async (model, subCollect, obj, file, etudiant_id) => {
    try {
      const urlToDel = file.url;
      const imageRef = storage.refFromURL(urlToDel);
      await imageRef.delete().catch((error) => {
        throw error;
      });

      if (model === 'session') {
        await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.session)
          .doc(String(obj.uid))
          .collection(subCollect)
          .doc(String(etudiant_id))
          .collection(subCollect)
          .doc(String(file.uid))
          .delete();
      }
    } catch (error) {
      console.error(error, file);
      enqueueSnackbar('Error on remove file ', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const removeSingleFile = async (model, subCollect, obj, file) => {
    try {
      const urlToDel = file.url;
      const imageRef = storage.refFromURL(urlToDel);
      await imageRef.delete().catch((error) => {
        throw error;
      });

      if (model === 'session') {
        await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.session)
          .doc(String(obj.uid))
          .collection(subCollect)
          .doc(String(file.uid))
          .delete();
      }
    } catch (error) {
      console.error(error, file);
      enqueueSnackbar('Error on remove file ', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const downloadSingleFile = async (url) => {
    try {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('target', '_blank');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error(error, url);
      enqueueSnackbar('Error on download file', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  /* Courses student */
  const getStudentCourses = async (etudiant_id, level_label, parcours_id) => {
    try {
      return studentCoursesApi(env.company, {
        etudiant_id: etudiant_id,
        level_label: level_label,
        parcours_id: parcours_id
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getCourseInfo = async (enseignement_id) => {
    try {
      return courseInfoApi(env.company, { enseignement_id: enseignement_id });
    } catch (error) {
      console.error(error);
    }
  };

  const saveCourseInfo = async (enseignement_id, values) => {
    try {
      if (!firestore) return;
      await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.enseignement)
        .doc(String(enseignement_id))
        .set({ ...values });
      enqueueSnackbar('Sauvegarde réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const saveCoursePhoto = async (enseignement_id, value) => {
    try {
      if (!firestore) return;
      const docRef = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.enseignement)
        .doc(String(enseignement_id))
        .set({photo: value})
      enqueueSnackbar('Sauvegarde réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const getCourseInfoFB = async (enseignement_id) => {
    try {
      if (!enseignement_id) return;
      const docsCourse = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.enseignement)
        .doc(String(enseignement_id))
        .get();
      if (docsCourse && docsCourse.data()) {
        return docsCourse.data();
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getCourses = async (params) => {
    try {
      setLoading(true);
      if (isTeacher()) {
        if (!userFacforpro || !userFacforpro.facforpro_id) return;
        params = { ...params, professeur_id: userFacforpro.facforpro_id };
      }
      const res = await getCoursesApi(env.company, params);
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const getCourse = async (enseignement_id) => {
    try {
      setLoading(true);
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      const res = await getCourseByIdApi(env.company, { enseignement_id: enseignement_id });
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const getCreneauxTeacherCourse = async (enseignement_id, professeur_id, session_id) => {
    try {
      return getCreneauxTeacherCourseApi(env.company, {
        enseignement_id: enseignement_id,
        professeur_id: professeur_id,
        session_id: session_id
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getCreneauxByCourse = async (enseignement_id, session_id) => {
    try {
      return getCreneauxByCourseApi(env.company, {
        enseignement_id: enseignement_id,
        session_id: session_id
      });
    } catch (error) {
      console.error(error);
    }
  };
  const getCreneauSubCollect = async (creneau_id, subCollect) => {
    try {
      const docsSession = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.session)
        .doc(String(creneau_id))
        .collection(subCollect)
        .get();

      let arr = [];
      if (docsSession && docsSession.docs) {
        arr = docsSession.docs.map((doc) => {
          return { uid: doc.id, ...doc.data() };
        });
      }
      return arr;
    } catch (error) {
      console.error(error);
    }
  };
  const getConditionToSubCollect = (item, sbCollect) => {
    if (!sbCollect) return false;
    if (!item) return false;
    if (!item.createdAt) return false;
    if (sbCollect === COLLECTIONS.studentsNoted) {
      if (item.notation == null || item.notation == undefined)
        return Number(item.notation >= 0)
      return Number(item.notation) >= 0;
    } else if (sbCollect === COLLECTIONS.studentsAbs) {
      return Boolean(item.isPresent);
    }
  };
  const saveStudentsCreneauSubCollect = async (creneau_id, studentsCreno, subCollect) => {
    try {
      if (!firestore) return;
      setLoading(true);
      if (creneau_id && studentsCreno) {
        const promises = studentsCreno.map(async (it) => {
          const isOk = getConditionToSubCollect(it, subCollect);
          if (isOk) {
            const res = await firestore
              .collection(env.instance)
              .doc(env.company)
              .collection(COLLECTIONS.session)
              .doc(String(creneau_id))
              .collection(subCollect)
              .doc(String(it.etudiant_id))
              .set({ ...it, updatedAt: new Date() });
            return res;
          }
        });
        const resAll = await Promise.all(promises);
        enqueueSnackbar('Sauvegarde réussie', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } else {
        enqueueSnackbar('Veuillez vérifier les paramètres', {
          variant: 'warning',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error :', error);
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const getStudentsByCourse = async (enseignement_id) => {
    try {
      let res = await studentsByCourseApi(env.company, {
        enseignement_id: enseignement_id
      });
      if (res) {
        res = res.map((it) => {
          return {
            uid: it.uid,
            etudiant_id: it.etudiant_id,
            displayName: it.nom_usage ? it.nom_usage : '',
            firstname: it.prenom,
            lastname: it.nom,
            email: it.email,
            address: it.adresse_postale,
            city: it.code_postal,
            createdAt: it.updated_at,
            level: it.level_label,
            program: it.programme,
            sessionId: it.session_id,
            sessionName: it.session_name,
            presentiel: it.presentiel,
          };
        });
      }
      return res;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const saveStudentsNotes = async(etudiant_id, enseignement_id, session_id, programme_id, notation, comment) => {
    await addNoteApi(env.company, {
      etudiant_id: etudiant_id,
      enseignement_id: enseignement_id,
      session_id: session_id,
      programme_id: programme_id,
      note: notation,
      comment: comment,
    });
  }
  const getStudentsNotes = async(etudiant_id, enseignement_id, session_id, programme_id) => {
    return getNoteApi(env.company, {
      etudiant_id: etudiant_id,
      enseignement_id: enseignement_id,
      session_id: session_id,
      programme_id: programme_id,
    });
  }
  const getEvalsByCourse = async (enseignement_id) => {
    try {
      if (!enseignement_id) return;
      const docsEvals = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.enseignement)
        .doc(String(enseignement_id))
        .collection(COLLECTIONS.evaluation)
        .get();
      if (docsEvals && docsEvals.docs) {
        const arr = [];
        docsEvals.docs.map((doc) => {
          arr.push({ uid: doc.id, ...doc.data() });
        });
        return arr;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const saveEvalCourse = async (enseignement_id, evaluation_id, values) => {
    try {
      if (!firestore) return;
      if (evaluation_id) {
        /* case update */
        await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.enseignement)
          .doc(String(enseignement_id))
          .collection(COLLECTIONS.evaluation)
          .doc(evaluation_id)
          .set({ uid: evaluation_id, ...values });
      } else {
        /* case add */
        const doc = await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.enseignement)
          .doc(String(enseignement_id))
          .collection(COLLECTIONS.evaluation)
          .add({ ...values });
        const idDoc = doc.id;
        await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.enseignement)
          .doc(String(enseignement_id))
          .collection(COLLECTIONS.evaluation)
          .doc(idDoc)
          .set({ uid: idDoc, ...values });
      }

      enqueueSnackbar('Sauvegarde réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      console.error('qsdg', error);
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const deleteEvalCourse = async (enseignement_id, evalCourse) => {
    try {
      if (!firestore) return;
      if (enseignement_id && evalCourse && evalCourse.uid) {
        await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.enseignement)
          .doc(String(enseignement_id))
          .collection(COLLECTIONS.evaluation)
          .doc(evalCourse.uid)
          .delete();
      }

      enqueueSnackbar('Suppression réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      console.error('qsdg', error);
      enqueueSnackbar('Suppression échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const getChaptersByCourse = async (enseignement_id) => {
    try {
      if (!enseignement_id) return;
      const docsChapters = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.enseignement)
        .doc(String(enseignement_id))
        .collection(COLLECTIONS.writeChapter)
        .get();
      if (docsChapters && docsChapters.docs) {
        const arr = [];
        docsChapters.docs.map((doc) => {
          arr.push({ uid: doc.id, ...doc.data() });
        });
        return arr;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const saveChapterCourse = async (enseignement_id, chapter_id, values) => {
    try {
      if (!firestore) return;
      if (chapter_id) {
        /* case update */
        await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.enseignement)
          .doc(String(enseignement_id))
          .collection(COLLECTIONS.writeChapter)
          .doc(chapter_id)
          .set({ uid: chapter_id, ...values });
      } else {
        /* case add */
        const doc = await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.enseignement)
          .doc(String(enseignement_id))
          .collection(COLLECTIONS.writeChapter)
          .add({ ...values });
        const idDoc = doc.id;
        await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.enseignement)
          .doc(String(enseignement_id))
          .collection(COLLECTIONS.writeChapter)
          .doc(idDoc)
          .set({ uid: idDoc, ...values });
      }

      enqueueSnackbar('Sauvegarde réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      console.error('qsdg', error);
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const deleteChapterCourse = async (enseignement_id, chapterCourse) => {
    try {
      if (!firestore) return;
      if (enseignement_id && chapterCourse && chapterCourse.uid) {
        await firestore
          .collection(env.instance)
          .doc(env.company)
          .collection(COLLECTIONS.enseignement)
          .doc(String(enseignement_id))
          .collection(COLLECTIONS.writeChapter)
          .doc(chapterCourse.uid)
          .delete();
      }

      enqueueSnackbar('Suppression réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      console.error('qsdg', error);
      enqueueSnackbar('Suppression échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  const studentsNotedByCourse = async (enseignement_id, evalCourse) => {
    try {
      if (!firestore) return;
      const docsSt = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.enseignement)
        .doc(String(enseignement_id))
        .collection(COLLECTIONS.evaluation)
        .doc(evalCourse.uid)
        .collection(COLLECTIONS.studentsNoted)
        .get();
      let arr = [];
      if (docsSt && docsSt.docs) {
        arr = docsSt.docs.map((doc) => {
          return { ...doc.data() };
        });
      }
      return arr;
    } catch (error) {
      throw error;
    }
  };
  const getNotationStudentByCourseEval = async (
    enseignement_id,
    eval_id,
    etudiant_id
  ) => {
    if (!firestore) return;
    try {
      const doc = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.enseignement)
        .doc(String(enseignement_id))
        .collection(COLLECTIONS.evaluation)
        .doc(eval_id)
        .collection(COLLECTIONS.studentsNoted)
        .doc(String(etudiant_id))
        .get();
      if (doc && doc.data()) {
        return doc.data();
      }
      return null;
    } catch (error) {
      throw error;
    }
  };
  const getSubColectStudentByCreno = async (creneau_id, etudiant_id, subCollect) => {
    if (!firestore) return;
    try {
      const data = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.session)
        .doc(String(creneau_id))
        .collection(subCollect)
        .doc(String(etudiant_id))
        .collection(subCollect)
        .get();
      let arr = [];

      if (data && data.docs) {
        arr = data.docs.map((doc) => {
          return { uid: doc.id, ...doc.data() };
        });
      }
      return arr;
    } catch (error) {
      throw error;
    }
  };
  const getDocSubColectStudentByCreno = async (creneau_id, etudiant_id, subCollect) => {
    if (!firestore) return;
    try {
      const doc = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.session)
        .doc(String(creneau_id))
        .collection(subCollect)
        .doc(String(etudiant_id))
        .get();
      if (doc && doc.data()) {
        return doc.data();
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  const saveCourseEvalStudents = async (enseignement_id, evalCourse, evalStudents) => {
    try {
      if (!firestore) return;
      setLoading(true);
      if (enseignement_id && evalCourse && evalCourse.uid && evalStudents) {
        const promises = evalStudents.map(async (it) => {
          if (Number(it.notation) > 0) {
            const res = await firestore
              .collection(env.instance)
              .doc(env.company)
              .collection(COLLECTIONS.enseignement)
              .doc(String(enseignement_id))
              .collection(COLLECTIONS.evaluation)
              .doc(evalCourse.uid)
              .collection(COLLECTIONS.studentsNoted)
              .doc(String(it.etudiant_id))
              .set({ ...it, updatedAt: new Date() });
            return res;
          }
        });
        const resAll = await Promise.all(promises);
        enqueueSnackbar('Sauvegarde réussie', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } else {
        enqueueSnackbar('Veuillez vérifier les paramètres', {
          variant: 'warning',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('error', error);
      enqueueSnackbar('Sauvegarde échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const getSessionsActives = async (enseignement_id, session_id) => {
    try {
      return getSessionsActivesApi(env.company);
    } catch (error) {
      console.error(error);
    }
  };
  const proposeCrenoForCourse = async (params) => {
    try {
      const res = await proposeCrenoForCourseApi(env.company, params);
      enqueueSnackbar('Proposition envoyée', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      return res;
    } catch (error) {
      enqueueSnackbar('Proposition a été échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      console.error(error);
    }
  };

  const addToWhitelist = async (stu) => {
    try {
      await addToWhitelistApi(env.company, {
        etudiant_id: stu.uid,
      });
      enqueueSnackbar('Ajout réussi', {
        variant: 'success',
        action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
        )
      });
      setUpdatedWhitelistIn(new Date());
    } catch (error) {
      enqueueSnackbar('Ajout échoué', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      throw error;
    }
  };
  const removeFromWhitelist = async (stu) => {
    try {
      await removeFromWhitelistApi(env.company, {
        etudiant_id: stu.id,
      });
      enqueueSnackbar('Suppression réussie', {
        variant: 'success',
        action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
        )
      });
      setUpdatedWhitelistIn(new Date());
    } catch (error) {
      enqueueSnackbar('Suppression échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      throw error;
    }
  };

  const isInWhiteList = (student, whitelist) => {
    if (student.presentiel) return true;
    if (!whitelist || whitelist.length == 0) return false;
    const found = whitelist.find((it) => {
      return String(it.id) == String(student.id);
    });
    return found ? true : false;
  };

  const getReplayCourse = async (enseignement_id) => {
    try {
      const docCourse = await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.enseignement)
        .doc(String(enseignement_id))
        .get();
      if (docCourse && docCourse.data()) {
        const obj = docCourse.data();
        return obj.replay || [];
      }
      return [];
    } catch (error) {
      console.error(error);
    }
  };
  const saveReplayCourse = async (enseignement_id, values) => {
    try {
      await firestore
        .collection(env.instance)
        .doc(env.company)
        .collection(COLLECTIONS.enseignement)
        .doc(String(enseignement_id))
        .set({ ...values, updatedAt: new Date() });

      enqueueSnackbar('Ajout Replay réussi', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      enqueueSnackbar('Ajout de Vidéo replay échoué', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      throw error;
    }
  };
  const updStatusCreneau = async (creneau_id, values) => {
    try {
      if (!creneau_id) return;
      const res = await updCreneauByIdApi(env.company, { creneau_id, values: { ...values } });

      enqueueSnackbar('Mise à jour du statut réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      enqueueSnackbar('Mise à jour du statut échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      throw error;
    }
  };
  const getTeacherById = async (professeur_id) => {
    try {
      if (!professeur_id) return;
      const u = await getTeacherByIdApi(env.company, { professeur_id: professeur_id });
      return u;
    } catch (error) {
      console.error(error);
    }
  };
  const updTeacherById = async (professeur_id, values) => {
    try {
      if (!professeur_id) return;
      const res = await updTeacherByIdApi(env.company, { professeur_id, values: { ...values } });

      enqueueSnackbar('Mise à jour  réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      enqueueSnackbar('Mise à jour échouée', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      throw error;
    }
  };
  const exportPlanningSession = async (session_id, title) => {
    try {
      if (!session_id) return;
      if (!isTeacher() || !userFacforpro || !userFacforpro.facforpro_id) {
        return;
      }
      return await exportPlanningSessionApi(env.company, { prof_id: userFacforpro.facforpro_id, session_id:  session_id, title: title });
    } catch (error) {
      console.error(error);
    }
  };
  const exportPlanningSessionXls = async (session_id, title) => {
    try {
      if (!session_id) return;
      if (!isTeacher() || !userFacforpro || !userFacforpro.facforpro_id) {
        return;
      }
      return await exportPlanningSessionXlsApi(env.company, { prof_id: userFacforpro.facforpro_id, session_id:  session_id, title: title });
    } catch (error) {
      console.error(error);
    }
  };
  const getTeachers = async () => {
    try {
      const res = await getTeachersApi(env.company, {});
      if (res) return res;
      return [];
    } catch (error) {
      throw error;
    }
  };
  const getTeachersActiv = async () => {
    try {
      const docsTeachers = await firestore
        .collection(COLLECTIONS.users)
        .where('companyuid', '==', env.company)
        .where('role', 'array-contains', 'teacher')
        .get();
      if (docsTeachers && docsTeachers.docs) {
        const arr = [];
        docsTeachers.docs.forEach((doc) => {
          const docData = doc.data();
          arr.push({
            id: doc.id,
            ...docData
          });
        });
        return arr;
      }
      return [];
    } catch (error) {
      throw error;
    }
  };
  const regix = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  const resetPwd = async (useruid, pass, email) => {
    try {
      if (pass && pass.length > 0 && regix.test(pass) == false) {
        enqueueSnackbar(
          'Le mot de passe devrait avoir au minium 8 caractères, incluant au moins: un Chiffre, une Majuscule et un caractère spécial.',
          {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          }
        );
        return;
      }
      await resetUserPwdByIdApi({
        uid: useruid,
        pass: pass,
      });
      enqueueSnackbar('Réinitialisation du mot de passe réussie', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      enqueueSnackbar('Error on change passaword user', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const FIX_PASS = 'demo1234';
  const activateAccessTeacher = async (editUser, values) => {
    try {
      const res = await createUserApi({
        email: values.email,
        password: FIX_PASS
      });

      if (res && res.uid) {
        const userUid = res.uid;
        const doc = await firestore
          .collection(COLLECTIONS.users)
          .doc(userUid)
          .set({
            ...values,
            id: userUid,
            uid: userUid,
            role: ['teacher'],
            companyuid: env.company,
            instance: env.instance,
            createdBy: { ...user },
            createdAt: new Date(),
            displayName: editUser.displayName,
            email_perso: editUser.email_perso,
            firstname: editUser.firstname,
            lastname: editUser.lastname,
            phoneNumber: editUser.phoneNumber,
          });
        enqueueSnackbar(`Création réussie`, {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    } catch (error) {
      enqueueSnackbar('Error on creating teacher', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      throw error;
    }
  };
  const saveTeacher = async (editedUser, values) => {
    try {
      if (editedUser && editedUser.uid) {
        await firestore
          .collection(COLLECTIONS.users)
          .doc(editedUser.uid)
          .set({
            ...editedUser,
            uid: editedUser.uid,
            ...values,
            updatedBy: { ...user },
            updatedAt: new Date()
          });
        await updateUserApi({
          uid: editedUser.uid,
          email: editedUser.email,
        });
        enqueueSnackbar(`${editedUser.uid ? 'Mise à jour' : 'Création'} réussie`, {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Error on saving user', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  return (
    <FacforproContext.Provider
      value={{
        courseInfo,
        getCreneau,
        getStudentInfo,
        getStudentTimer,
        saveStudentReplayTimer,
        saveStudentLiveTimer,
        saveCreneauInfo,
        uploadFiles,
        uploadStudentFiles,
        downloadSingleFile,
        removeSingleFile,
        userFacforpro,
        getStudentCourses,
        getCourseInfo,
        getCourses,
        saveCourseInfo,
        getCourseInfoFB,
        getTeachers,
        getTeachersActiv,
        getCourse,
        getCreneauxTeacherCourse,
        getCreneauxByCourse,
        getStudentsByCourse,
        saveStudentsNotes,
        getStudentsNotes,
        getEvalsByCourse,
        saveEvalCourse,
        deleteEvalCourse,
        deleteChapterCourse,
        saveChapterCourse,
        getChaptersByCourse,
        saveCourseEvalStudents,
        studentsNotedByCourse,
        getCreneauSubCollect,
        saveStudentsCreneauSubCollect,
        getSessionsActives,
        proposeCrenoForCourse,
        getNotationStudentByCourseEval,
        getDocSubColectStudentByCreno,
        getCreneauInfo,
        getSubColectStudentByCreno,
        removeSingleStudentFile,
        addToWhitelist,
        removeFromWhitelist,
        whiteListStudent,
        isInWhiteList,
        saveReplayCourse,
        getReplayCourse,
        updStatusCreneau,
        getTeacherById,
        updTeacherById,
        exportPlanningSession,
        exportPlanningSessionXls,
        resetPwd,
        saveTeacher,
        activateAccessTeacher,
        getPresenceIframeUrl,
        loading,
      }}
    >
      {props.children}
    </FacforproContext.Provider>
  );
};
export default FacforproProvider;
