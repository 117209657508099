// @ts-nocheck
/* eslint-disable */
import { format, formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/esm/locale';
// ----------------------------------------------------------------------

export function fDate(dt) {
  try {
    if (typeof dt === 'object' && !(dt instanceof Date)) {
      dt = dt.toDate();
    }
    return format(new Date(dt), 'dd MMMM yyyy', { locale: fr });
  } catch (error) {
    return '';
  }
}

export function fDateTime(dt) {
  try {
    if (typeof dt === 'object' && !(dt instanceof Date)) {
      dt = dt.toDate();
    }
    return format(new Date(dt), 'dd MMM yyyy HH:mm', { locale: fr });
  } catch (error) {
    return '';
  }
}

export function fDateTimeSuffix(dt) {
  try {
    return format(new Date(dt), 'dd/MM/yyyy HH:mm p', { locale: fr });
  } catch (error) {
    return '';
  }
}

export function fToNow(dt) {
  try {
    return formatDistanceToNow(new Date(dt), {
      addSuffix: true
    });
  } catch (error) {
    return '';
  }
}
export function fDateWithFormat(dt, formatDt) {
  try {
    if (typeof dt === 'object' && !(dt instanceof Date)) {
      dt = dt.toDate();
    }
    return format(new Date(dt), formatDt ? formatDt : 'PPPP', { locale: fr });
  } catch (error) {
    return '';
  }
}
