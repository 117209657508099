// @ts-nocheck
/* eslint-disable */

import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ url, ...other }) {
  const logoImg = url ? url : '/static/brand/FFP-elearning-web.png';
  return (
    <Box
      component="img"
      alt="logo"
      src={logoImg}
      // height={40}
      {...other}
    />
  );
}
