// @ts-nocheck
/* eslint-disable */
import { COMPANIES, FIREBASE_BACKEND } from '../config';
import axios from 'axios';

axios.defaults.headers.common['X-ELEARNING-TOKEN'] = process.env.REACT_APP_LARAVEL_ELEARNING_API_TOKEN;

export const getCoursesApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_courses, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getCourseByIdApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_course_by_id, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getCreneauxByCourseApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_creneau_by_course, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getTeachersApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_teachers, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getTeacherByIdApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_teacher_by_id, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getCreneauxTeacherCourseApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_creneaux_teacher_course, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getStudentPagingApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_students, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getCreneauPagingApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_creneaux, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getStudentCreneauPagingApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_creneaux_students, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getCreneauByIdApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_creneau_by_id, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getStudentByIdApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_student_info, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const studentCoursesApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_student_courses, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const courseInfoApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_course_info, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const studentsByCourseApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_students_by_course, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const addNoteApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_add_note, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getNoteApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_get_note, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const proposeCrenoForCourseApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_creno_propose, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getSessionsActivesApi = async (company_uid) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_session_activ);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updCreneauByIdApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_creneau_upd, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updTeacherByIdApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_teacher_upd, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const exportPlanningSessionApi = async (company_uid, params) => {
  return axios({
    url: COMPANIES[company_uid].url_export_planning_session,
    method: 'POST',
    responseType: 'blob',
    data: params
  });
};
export const exportPlanningSessionXlsApi = async (company_uid, params) => {
  return axios({
    url: COMPANIES[company_uid].url_export_planning_session_xls,
    method: 'POST',
    responseType: 'blob',
    data: params
  });
};
export const checkStudentByIdApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_student_check, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getEtudiantsPDF = async (company_uid, params) => {
  return axios({
    url: COMPANIES[company_uid].url_student_pdf,
    method: 'POST',
    responseType: 'blob',
    data: params
  });
}

// Firebase
export const createUserApi = async (params) => {
  try {
    const res = await axios.post(FIREBASE_BACKEND.url_user_create, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updateUserApi = async (params) => {
  try {
    const res = await axios.post(FIREBASE_BACKEND.url_user_update, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const deleteUserByIdApi = async (params) => {
  try {
    const res = await axios.post(FIREBASE_BACKEND.url_user_delete, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const resetUserPwdByIdApi = async (params) => {
  try {
    const res = await axios.post(FIREBASE_BACKEND.url_user_reset_pwd, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPresenceIframeUrl = (company_uid, etudiant_id, creneau_id) => {
  return COMPANIES[company_uid].url_presence_iframe + '/' + etudiant_id + '/' + creneau_id;
};

export const getWhitelistApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_get_whitelist, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addToWhitelistApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_add_to_whitelist, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const removeFromWhitelistApi = async (company_uid, params) => {
  try {
    const res = await axios.post(COMPANIES[company_uid].url_remove_from_whitelist, params);
    return res.data;
  } catch (error) {
    throw error;
  }
};
