import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
// redux
import { store } from '../../redux/store';
// hooks
import useAuth from '../../hooks/useAuth';
//
import { firebaseConfig } from '../../config';

import { PATH_DASHBOARD, PATH_AUTH } from '../../routes/paths';

// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  const authentication = firebase.auth();
  /*   authentication.useEmulator('http://localhost:9099');
   */
}

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true
  },
  dispatch: store.dispatch,
  createFirestoreInstance
};

// ----------------------------------------------------------------------

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

export default function FirebaseProvider({ children }) {
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    firebase.auth();
    const getInitialize = async () => {
      try {
        firebase.auth().onAuthStateChanged(async (u) => {
          if (u) {
            const doc = await firebase.firestore().collection('users').doc(u.uid).get();
            const user = doc ? doc.data() : null;
            if (user && user.role) {
              let urlRedirect = '/';
              if (user.role.includes('superadmin')) {
                urlRedirect = PATH_DASHBOARD.general.entreprises;
              }
              if (user.role.includes('superadmincom')) {
                urlRedirect = PATH_DASHBOARD.admin.administrateurs;
              }
              if (user.role.includes('admin')) {
                urlRedirect = PATH_DASHBOARD.admin.administrateurs;
              }
              if (user.role.includes('teacher')) {
                urlRedirect = PATH_DASHBOARD.general.app;
              }
              if (user.role.includes('student')) {
                urlRedirect = PATH_DASHBOARD.general.app;
              }
              const path = location.pathname;
              if (path !== PATH_AUTH.login && path !== urlRedirect) {
                history.push(urlRedirect);
              }
            }
            /* Create directely in firebase collection users */
            /* firebase
              .firestore()
              .collection('users')
              .doc(user.uid)
              .set(
                {
                  role: ADMIN_EMAILS.includes(user.email) ? 'admin' : 'user'
                },
                { merge: true }
              ); */
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    getInitialize();
  }, [isAuthenticated]);

  return (
    <ReactReduxFirebaseProvider {...rrfProps}>{children}</ReactReduxFirebaseProvider>
  );
}
