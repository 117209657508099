import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import homeFill from '@iconify/icons-eva/home-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, AppBar, Hidden, Toolbar, IconButton, Tooltip } from '@material-ui/core';
//
import Searchbar from '../dashboard/Searchbar';
import AccountPopover from '../dashboard/AccountPopover';
import LanguagePopover from '../dashboard/LanguagePopover';
import NotificationsPopover from '../dashboard/NotificationsPopover';
import { AppContext } from '../../provider/AppProvider';
import { PATH_DASHBOARD } from '../../routes/paths';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  width: '100%',
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72)
  /*   [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  } */
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

SuperAdminNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function SuperAdminNavbar({ onOpenSidebar }) {
  const { company } = useContext(AppContext);
  return (
    <RootStyle>
      <ToolbarStyle>
        <Hidden lgUp>
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        {/* <Searchbar /> */}
        <Tooltip title="Liste des entreprises">
          <IconButton
            component={Link}
            to={PATH_DASHBOARD.general.entreprises}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={homeFill} />
          </IconButton>
        </Tooltip>
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: { xs: 1.5, sm: 2, lg: 3 }
            }
          }}
        >
          <NotificationsPopover />
          <AccountPopover />
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
