import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// material
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// routes

import Routing, { routes } from './routes';
// redux
import { store, persistor } from './redux/store';
// theme
import ThemeConfig from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';

// Using for Auth (Check doc https://minimals.cc/docs/authentication)
// import JwtProvider from './components/authentication/JwtProvider';
import FirebaseProvider from './components/authentication/FirebaseProvider';
import AppProvider from './provider/AppProvider';
// ----------------------------------------------------------------------

const history = createBrowserHistory();

export default function App() {
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <Router history={history}>
            <FirebaseProvider>
              <ThemeConfig>
                <AppProvider>
                  <RtlLayout>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <NotistackProvider>
                        <Settings />
                        <ScrollToTop />
                        <GoogleAnalytics />
                        <Routing />
                      </NotistackProvider>
                    </LocalizationProvider>
                  </RtlLayout>
                </AppProvider>
              </ThemeConfig>
            </FirebaseProvider>
          </Router>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}
