import NProgress from 'nprogress';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Suspense, Fragment, lazy, useEffect, useMemo } from 'react';
// material
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import HeaderDashboard from '../components/HeaderDashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
//
import { PATH_DASHBOARD, PATH_PAGE, PATH_AUTH } from './paths';
import FacforproProvider from '../provider/FacforproProvider'
import DashboardRoutes from './dashboard.routes';
import HomeRoutes from './home.routes';
import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard';
import CompanyLayout from '../layouts/company';

const Page404 = lazy(() => import('../views/Page404'));
const Page401 = lazy(() => import('../views/Page401'));

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);
  return <>{props.type === 'public' ? <Route {...props} /> : <Route {...props} />}</>;
}
const Routing = () => {
  const { user, isAuthenticated } = useAuth();
  const userHasRequiredRole = (u, r) => {
    if (!r || r.length === 0) return true;
    for (let i = 0; i < r.length; i += 1) {
      if (u.role.includes(r[i])) {
        return true;
      }
    }
    return false;
  };
  const generateBreadCrumbArray = (user, url, path) => {
    const uidRegex = /^[0-9\b]+$/
    const linksTab = []
    url.shift()
    for (let i = 0, rpath = ''; i < url.length; i += 1) {
      // Dashboard
      if (url[i] === '/dashboard')
        linksTab.push({name: 'Tableau de bord', href: rpath += url[i]})
      else if (url[i] === '/app')
        rpath += url[i]
      // Chat
      else if (url[i] === '/chat')
        linksTab.push({name: 'Messagerie', href: rpath += url[i]})
      else if (url[i] === '/new')
        linksTab.push({name: 'Nouveau message', href: rpath += url[i]})
      // ADM
      else if (url[i] === '/admCourseInfo')
        linksTab.push({name: 'Liste des cours', href: rpath += '/admCourseList'})
        else if (url[i] === '/admCourseList')
        linksTab.push({name: 'Liste des cours', href: rpath += '/admCourseList'})
      else if (url[i] === '/session' && !user.role.includes('student'))
        linksTab.push({name: 'Session', href: rpath += '/session'})
      // student profile
      else if (url[i] === '/user')
        rpath += url[i]
      else if (url[i] === '/profile')
        linksTab.push({name: 'Profil', href: rpath += url[i]})
      // Courses
      else if (url[i] === '/studentcourses')
        linksTab.push({name: 'Liste des cours', href: rpath += url[i]})
      else if (url[i] === '/courseInfo')
        linksTab.push({name: 'Liste des cours', href: rpath += '/studentcourses'})
      else if (uidRegex.test(url[i].substring(1)) && path.includes('/:uid'))
        linksTab.push({name: 'Cours', href: rpath += url[i]})
      else if (uidRegex.test(url[i].substring(1)) && path.includes('/:id'))
        linksTab.push({name: 'Profil étudiant', href: rpath += url[i]})
      // Written Courses
      else if (url[i] === '/students')
        linksTab.push({name: 'Liste des étudiants', href: rpath += url[i]})
      else if (url[i] === '/courseSupport')
        linksTab.push({name: 'Support de cours écrits', href: rpath += url[i]})
      // Calendar
      else if (url[i] === '/session' && user.role.includes('student'))
        linksTab.push({name: 'Calendrier', href: rpath += '/calendar'})
      else if (url[i] === '/calendar')
        linksTab.push({name: 'Calendrier', href: rpath += '/calendar'})
      else
        linksTab.push({name: url[i].substring(1), href: rpath += url[i]})
    }
    return linksTab;
  }
  const getPrivRoutes = useMemo(() => {
    console.log()
    return (
      <>
        {privRoutes.map((route) => {
          const Component = route.component;
          const role = route.roles;
          const Layout = route.path.startsWith(PATH_DASHBOARD.general.entreprises)
            ? CompanyLayout
            : DashboardLayout || Fragment;
          return (
            <Route
              key={Math.random()}
              path={route.path}
              exact={route.exact}
              user={user}
              isAuthenticated={isAuthenticated}
              render={(props) => {
                const isAuthorized = userHasRequiredRole(user, role)
                const linksTab = generateBreadCrumbArray(user, props.match.url.split('/').map(elem => elem = `/${elem}`),
                props.match.path)
                return isAuthenticated && isAuthorized ? (
                  <Layout>
                    <Container maxWidth="xl">
                      <HeaderDashboard
                        links={linksTab}
                      />
                      <Component {...props} />
                    </Container>
                  </Layout>
                ) : (
                  <Redirect
                    to={{
                      pathname: !isAuthenticated ? PATH_AUTH.login : PATH_PAGE.page401
                    }}
                  />
                );
              }}
            />
          );
        })}
        <Route path={PATH_PAGE.page401} exact component={Page401} />
      </>
    );
  }, [user]);
  const getDefPageFromRole = (u) => {
    if (!u) return '/';
    if (u.role.length > 0) {
      let urlRedirect = '/';
      if (u.role.includes('superadmin')) {
        urlRedirect = PATH_DASHBOARD.general.entreprises;
      }
      if (u.role.includes('superadmincom')) {
        urlRedirect = PATH_DASHBOARD.admin.administrateurs;
      }
      if (u.role.includes('admin')) {
        urlRedirect = PATH_DASHBOARD.admin.administrateurs;
      }
      if (u.role.includes('teacher')) {
        urlRedirect = PATH_DASHBOARD.general.app;
      }
      if (u.role.includes('student')) {
        urlRedirect = PATH_DASHBOARD.general.app;
      }
      return urlRedirect;
    }
    return '/';
  };
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route
          exact
          path="/"
          user={user}
          isAuthenticated={isAuthenticated}
          render={(props) => {
            const urlRedirect = getDefPageFromRole(user);
            return isAuthenticated ? (
              <Redirect to={urlRedirect} />
            ) : (
              <Redirect to={PATH_AUTH.login} />
            );
          }}
        />
        {routes.map((route, idx) => {
          const Component = route.component;
          const Layout = route.layout || Fragment;
          return (
            <Route
              key={Math.random()}
              path={route.path}
              exact={route.exact}
              user={user}
              isAuthenticated={isAuthenticated}
              render={(props) => {
                const urlRedirect = getDefPageFromRole(user);
                return !isAuthenticated ? (
                  <Layout>
                    <Component {...props} />
                  </Layout>
                ) : (
                  <Redirect
                    to={{
                      pathname: isAuthenticated && user ? urlRedirect : '/'
                    }}
                  />
                );
              }}
            />
          );
        })}
        {getPrivRoutes}

        {extraroutes.map((route, idx) => {
          const Component = route.component;
          const Layout = route.layout || Fragment;
          return (
            <Route
              key={Math.random()}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          );
        })}

        <Route component={Page404} />
      </Switch>
    </Suspense>
  );
};
export default Routing;

export const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuestGuard,
    path: PATH_AUTH.login,
    component: lazy(() => import('../views/authentication/Login'))
  },
  {
    exact: false,
    guard: GuestGuard,
    path: `/loginauto`,
    component: lazy(() => import('../views/authentication/LoginAuto'))
  },
  {
    exact: true,
    path: PATH_AUTH.register,
    component: lazy(() => import('../views/authentication/Register'))
  },
  {
    exact: true,
    path: PATH_AUTH.registerUnprotected,
    component: lazy(() => import('../views/authentication/Register'))
  },
  {
    exact: true,
    path: PATH_AUTH.verify,
    component: lazy(() => import('../views/authentication/VerifyCode'))
  }
];
export const extraroutes = [
  {
    exact: true,
    path: PATH_PAGE.page404,
    component: lazy(() => import('../views/Page404'))
  }
];

const privRoutes = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_DASHBOARD.general.app,
    roles: ['superadmin', 'superadmincom', 'admin', 'teacher', 'student'],
    component: lazy(() => import('../views/GeneralApp'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.general.entreprises,
    roles: ['superadmin'],
    component: lazy(() => import('../views/EntrepriseList'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.general.entreprises}/:uid`,
    roles: ['superadmin'],
    component: lazy(() => import('../views/Entreprises'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.root,
    component: () => <Redirect to={PATH_DASHBOARD.general.app} />
  },

  // MANAGEMENT : USER
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_DASHBOARD.admin.custom,
    roles: ['superadmin', 'superadmincom'],
    component: lazy(() => import('../views/CompanyCustom'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.admin.administrateurs,
    roles: ['superadmin', 'superadmincom', 'admin'],
    component: lazy(() => import('../views/AdminList'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.administrateurs}/:uid`,
    component: lazy(() => import('../views/Users'))
  },

  {
    exact: true,
    roles: ['superadmin', 'superadmincom'],
    path: `${PATH_DASHBOARD.admin.ffpadmin}/:uid`,
    component: lazy(() => import('../views/facforpro/admin/Admin'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.admin.teachers,
    roles: ['superadmin', 'superadmincom', 'admin', 'teacher'],
    component: lazy(() => import('../views/TeacherList'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.teachers}/:uid`,
    roles: ['superadmin', 'superadmincom', 'admin', 'teacher'],
    component: lazy(() => import('../views/Users'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.admin.students,
    component: lazy(() => import('../views/StudentList'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.admin.studentProfile,
    component: lazy(() => import('../views/StudentProfile'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.admin.whitelist,
    roles: ['superadmin', 'superadmincom', 'admin'],
    component: lazy(() => import('../views/facforpro/whitelist/Whitelist'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.programs}/:uid`,
    component: lazy(() => import('../views/Program'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.admin.courseList,
    roles: ['superadmin', 'superadmincom', 'admin', 'teacher'],
    component: lazy(() => import('../views/CourseList'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.user.courseList,
    roles: ['superadmin', 'superadmincom', 'admin', 'teacher', 'student'],
    component: lazy(() => import('../views/course/StudentCourses'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.admin.course,
    component: lazy(() => import('../views/Course'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.course}/:uid`,
    component: lazy(() => import('../views/Course'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.course}/:uid/:teacheruid`,
    component: lazy(() => import('../views/Course'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.courseInfo}/:uid`,
    component: lazy(() => import('../views/Course'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.user.courseInfo}/:uid`,
    component: lazy(() => import('../views/CourseInfo'))
  },

  {
    exact: true,
    path: PATH_DASHBOARD.admin.session,
    component: lazy(() => import('../views/facforpro/FacforproSessionList'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.session}/:uid`,
    component: lazy(() => import('../views/Session'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.sessionInfo}`,
    component: lazy(() => import('../views/SessionInfo'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.assignments}`,
    component: lazy(() => import('../views/assignments/AdmAssignmentList'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.assignments}/:uid`,
    component: lazy(() => import('../views/assignments/AdmAssignments'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.sessionfac}`,
    component: lazy(() => import('../views/sessionfac/AdmSessionFacList'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.admin.sessionfac}/:uid`,
    component: lazy(() => import('../views/sessionfac/AdmSessionFac'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.user.cards,
    component: lazy(() => import('../views/UserCards'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.user.list,
    component: lazy(() => import('../views/UserList'))
  },

  {
    exact: true,
    path: PATH_DASHBOARD.user.account,
    roles: ['superadmin', 'superadmincom', 'admin', 'teacher'],
    component: lazy(() => import('../views/UserAccount'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.chat.root,
    component: lazy(() => import('../views/Chat'))
  },
  {
    exact: true,
    path: `${PATH_DASHBOARD.chat.root}/:uidDest`,
    component: lazy(() => import('../views/Chat'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.user.profile,
    component: lazy(() => import('../views/UserProfile'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.user.root,
    component: () => <Redirect to={PATH_DASHBOARD.user.profile} />
  },
  {
    exact: true,
    path: PATH_DASHBOARD.mail.root,
    component: () => <Redirect to={PATH_DASHBOARD.mail.all} />
  },
  {
    exact: true,
    path: PATH_DASHBOARD.user.livecourse,
    component: lazy(() => import('../views/LiveCourseNotif'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.admin.absences,
    component: lazy(() => import('../views/facforpro/StudentAbsences'))
  },

  // APP : CALENDAR
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_DASHBOARD.calendar,
    component: lazy(() => import('../views/Calendar'))
  },
  {
    exact: true,
    path: PATH_DASHBOARD.courseSupport,
    component: lazy(() => import('../views/CourseSupport'))
  }
];
