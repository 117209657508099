import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

export default function GuestProtect({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();

  console.log(user, children);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    if (user.role.includes('superadmin')) {
      return <Redirect to={PATH_DASHBOARD.general.entreprises} />;
    }
    if (user.role.includes('admin')) {
      return <Redirect to={PATH_DASHBOARD.admin.administrateurs} />;
    }
    if (user.role.includes('teacher')) {
      return <Redirect to={PATH_DASHBOARD.general.app} />;
    }
    if (user.role.includes('student')) {
      return <Redirect to={PATH_DASHBOARD.general.app} />;
    }
    return null;
  }

  return <>{children}</>;
}
