// @ts-nocheck
/* eslint-disable */
import PropTypes from 'prop-types';
import { useEffect, useContext, Fragment } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
// material
import { Icon } from '@iconify/react';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  Button,
  Drawer,
  Hidden,
  Typography,
  ListSubheader
} from '@material-ui/core';
import colorPaletteOutline from '@iconify/icons-eva/color-palette-outline';

import WifiOutline from '@iconify/icons-eva/wifi-outline';
// hooks
import useAuth from '../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
//
import MenuLinks from './SidebarConfig';
import SidebarItem from './SidebarItem';
import { AppContext } from '../../provider/AppProvider';
import BtnLiveCourse from 'src/views/facforpro/student/BtnLiveCourse';
import { getCompanyIdByName } from 'src/config';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.primary.main, 0.08)
      : theme.palette.primary.lighter
}));

// ----------------------------------------------------------------------

function reduceChild({
  array,
  item,
  pathname,
  level,
  list,
  user,
  myCompany,
}) {
  if (item.hidden) {
    for (let i = 0; i < item.hidden.length; i++) {
      if (myCompany.uid == getCompanyIdByName(item.hidden[i])) return [...array];
    }
  }

  const key = item.href + level;

  if (list) {
    if (item.items) {
      const match = matchPath(pathname, {
        path: item.href,
        exact: false
      });
      return [
        ...array,
        <SidebarItem
          key={key}
          level={level}
          icon={item.icon}
          info={item.info}
          href={item.href}
          title={item.title}
          open={Boolean(match)}
        >
          {renderSidebarItems({
            pathname,
            level: level + 1,
            items: item.items,
            user,
            myCompany,
          })}
        </SidebarItem>
      ];
    }
  }

  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={item.href}
      icon={item.icon}
      info={item.info}
      title={item.title}
    />
  ];
}

function renderSidebarItems({
  items,
  pathname,
  level = 0,
  list,
  user,
  myCompany,
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) =>
          reduceChild({
            array,
            item,
            pathname,
            level,
            list,
            user,
            myCompany,
          }),
        []
      )}
    </List>
  );
}

function renderRole(role) {
  if (role.includes('admin')) {
    return 'Administrateur';
  }
  if (role.includes('student')) {
    return 'Apprenant';
  }
  if (role.includes('teacher')) {
    return 'Professeur';
  }
  return '';
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { myCompany, env, isStudent } = useContext(AppContext);

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  if (!myCompany || !myCompany.uid) return null;

  /* logo 'https://www.facforpro.com/wp-content/uploads/2020/08/FFP-elearning-web.png' */
  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          {myCompany.logo ? (
            <>
              <Logo url={myCompany.logo[0].url} sx={{ maxWidth: '100px' }} />
            </>
          ) : 'Accueil'}
        </RouterLink>
      </Box>

      <Link underline="none" component={RouterLink} to={!user.role.includes('student') && PATH_DASHBOARD.user.account}>
        <AccountStyle>
          <MyAvatar />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {user.firstname} {user.lastname}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {renderRole(user.role)}
            </Typography>
          </Box>
        </AccountStyle>
      </Link>
      
      {user.role.includes('superadmincom') && (
        <>
          <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.admin.custom}>
            <AccountStyle>
              <Box sx={{ ml: 2, flexDirection: 'row' }}>
                <Icon icon={colorPaletteOutline} style={{ marginRight: 10 }} />
                Personnalisation
              </Box>
            </AccountStyle>
          </Link>
        </>
      )}

      {MenuLinks.map((list) => {
        return (
          <Fragment key={Math.random()}>
            {user.role.includes(list.role) ? (
              <List
                disablePadding
                key={Math.random()}
                subheader={
                  <ListSubheader
                    disableSticky
                    disableGutters
                    sx={{
                      mt: 3,
                      mb: 2,
                      pl: 5,
                      color: 'text.primary',
                      typography: 'overline'
                    }}
                  >
                    {list.subheader}
                  </ListSubheader>
                }
              >
                {renderSidebarItems({
                  items: list.items,
                  pathname,
                  list,
                  user,
                  myCompany,
                })}
              </List>
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
      {isStudent() && <BtnLiveCourse />}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
