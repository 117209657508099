// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { Box, Link, makeStyles, useTheme } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import WifiOutline from '@iconify/icons-eva/wifi-outline';
import { AppContext } from 'src/provider/AppProvider';
import { FacforproContext } from 'src/provider/FacforproProvider';
import { getStudentCreneauPagingApi } from 'src/_apis_/facforpro_api';
import { fDateWithFormat } from 'src/utils/formatTime';
import { addDays, format, parse } from 'date-fns';

const useStyles = makeStyles({
  '@keyframes flicker': {
    from: {
      opacity: 1
    },
    to: {
      opacity: 0.5
    }
  },
  flicker: {
    animationName: '$flicker',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out'
  },
  withAnimation: ({ disabled }) => ({
    animationPlayState: disabled ? 'paused' : 'running'
  })
});

const INVERVAL = 1000 * 60 * 15; //15 minutes;
const MAX_LIVECOURSE = 2;

const LinkToLivecourse = ({ nextLiveCourse }) => {
  const theme = useTheme();
  const { env } = useContext(AppContext);
  const { userFacforpro } = useContext(FacforproContext);
  const [currentLiveCourse, setcurrentLiveCourse] = useState(null);
  const { flicker, withAnimation } = useStyles({ disabled: false });

  useEffect(() => {
    if (!userFacforpro) return;
    initLiveCourse();
  }, [userFacforpro]);

  useEffect(() => {
    if (!userFacforpro) return;
    const interval = setInterval(async () => {
      initLiveCourse();
    }, INVERVAL);
    return () => clearInterval(interval);
  }, [userFacforpro]);

  const initLiveCourse = async () => {
    if (!userFacforpro) return;
    try {
      const param = {
        programme_id: userFacforpro.programme_id,
        level_label: userFacforpro.level_label,
        course_id: userFacforpro.course_id,
        session_id: userFacforpro.session_id
      };
      if (Number(nextLiveCourse) > 0) {
        param.nextLiveCourse = nextLiveCourse;
      } else {
        param.liveCourse = MAX_LIVECOURSE;
      }
      const res = await getStudentCreneauPagingApi(env.company, param);
      const arr = res ? res : [];
      if (arr.length > 0) {
        setcurrentLiveCourse(arr[0]);
      } else {
        setcurrentLiveCourse(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!currentLiveCourse) return <></>;

  const getText = () => {
    try {
      const startDateFormatted = fDateWithFormat(
        parse(currentLiveCourse.start_at, 'dd/MM/yyyy HH:mm', new Date()),
        'p'
      );
      const endDateFormatted = fDateWithFormat(
        parse(currentLiveCourse.end_at, 'dd/MM/yyyy HH:mm', new Date()),
        'p'
      );
      return startDateFormatted + ' - ' + endDateFormatted;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  const getDay = () => {
    try {
      const startDateFormatted = fDateWithFormat(
        parse(currentLiveCourse.start_at, 'dd/MM/yyyy HH:mm', new Date()),
        'PPP'
      );
      return startDateFormatted;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  return (
    <>
      <Link
        underline="none"
        component={RouterLink}
        to={`${PATH_DASHBOARD.admin.session}/${currentLiveCourse.uid}`}
      >
        <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
          {Number(nextLiveCourse) > 0 && <p>Prochain cours</p>}
        </Box>
        <div
          className={`${flicker} ${withAnimation}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2, 2.5),
            margin: theme.spacing(1, 2.5, 5),
            borderRadius: theme.shape.borderRadiusSm,
            color: theme.palette.grey[800],
            backgroundColor: theme.palette.primary.main
          }}
        >
          <Box sx={{ ml: 2, flexDirection: 'row', fontSize: 12 }}>
            <Icon icon={WifiOutline} style={{ marginRight: 10 }} />

            {currentLiveCourse.enseignement_thematique}
            <p style={{ fontSize: 12 }}>{getDay()}</p>
            <p style={{ fontSize: 12 }}>{getText()}</p>
            <p style={{ fontSize: 12 }}>Salle : {currentLiveCourse.salle}</p>
          </Box>
        </div>
      </Link>
    </>
  );
};
export default LinkToLivecourse;
